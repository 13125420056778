var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card mt-5",class:_vm.$style.container},[_vm._m(0),_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"h1",class:_vm.$style.welcomeText},[_vm._v("Selamat datang!")]),_c('div',{staticClass:"h6"},[_vm._v("Masuk ke akun Sistem Rekam Medis Elektronik")])]),_c('button',{staticClass:"text-center w-100 mb-4 d-flex align-items-center justify-content-center",class:_vm.$style.googleButton,attrs:{"size":"large"},on:{"click":_vm.handleGoogleLogin}},[_c('img',{staticClass:"mr-2",attrs:{"src":"resources/images/google-icon.svg","alt":"Google","height":"26"}}),_c('div',{staticClass:"font-size-18 justify-content-center"},[_vm._v(" Masuk dengan Google ")]),_c('div',{ref:"googleLoginBtn",attrs:{"hidden":""}})]),_c('div',{staticClass:"d-flex align-items-center justify-content-center my-3"},[_c('div',{class:_vm.$style.line}),_c('span',{staticClass:"mx-2"},[_vm._v("atau masuk dengan")]),_c('div',{class:_vm.$style.line})]),_c('a-form',{staticClass:"mb-4",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('div',{staticClass:"h6 font-weight-bold"},[_vm._v(" Email"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email',
            {
              initialValue: '',
              rules: [
                { required: true, message: 'Please input your Email!' },
                { type: 'email', message: 'The input is not valid E-mail!' } ],
            } ]),expression:"[\n            'email',\n            {\n              initialValue: '',\n              rules: [\n                { required: true, message: 'Please input your Email!' },\n                { type: 'email', message: 'The input is not valid E-mail!' },\n              ],\n            },\n          ]"}],attrs:{"size":"large","placeholder":"Masukkan email","id":"email","autocomplete":"email"}})],1),_c('a-form-item',[_c('div',{staticClass:"h6 font-weight-bold"},[_vm._v(" Kata Sandi"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              initialValue: '',
              rules: [
                { required: true, message: 'Please input your Password!' } ],
            } ]),expression:"[\n            'password',\n            {\n              initialValue: '',\n              rules: [\n                { required: true, message: 'Please input your Password!' },\n              ],\n            },\n          ]"}],attrs:{"size":"large","placeholder":"Masukkan kata sandi","id":"password","autocomplete":"current-password"}})],1),_c('a-button',{staticClass:"text-center w-100",class:_vm.$style.submitButton,attrs:{"htmlType":"submit","size":"large","type":"primary","loading":this.isLoading,"shape":"round"}},[_c('strong',{staticClass:"h5"},[_vm._v("Masuk")])])],1),_c('div',{staticClass:"text-left pt-2 mb-auto"},[_c('span',{staticClass:"mr-2"},[_vm._v("Belum punya akun?")]),_c('router-link',{staticClass:"kit__utils__link font-size-16",attrs:{"to":"/auth/register"}},[_vm._v("Daftar sekarang")])],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{staticClass:"mb-4",attrs:{"src":"resources/images/medisync-withtext.svg","alt":"Medisync","height":"52"}})])}]

export { render, staticRenderFns }