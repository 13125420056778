<template>
  <div>
    <!-- <div class="text-center mb-5">
      <h1 class="mb-5">
        <strong>Welcome to Medisync</strong>
      </h1>
    </div> -->
    <div class="card mt-5" :class="$style.container">
      <div>
        <img
          src="resources/images/medisync-withtext.svg"
          alt="Medisync"
          height="52"
          class="mb-4"
        />
      </div>
      <!-- <div class="text-dark font-size-24 mb-3">
        <strong>Sign in to your account</strong>
      </div> -->
      <div class="mb-4">
        <div class="h1" :class="$style.welcomeText">Selamat datang!</div>
        <div class="h6">Masuk ke akun Sistem Rekam Medis Elektronik</div>
      </div>

      <button
        size="large"
        class="text-center w-100 mb-4 d-flex align-items-center justify-content-center"
        :class="$style.googleButton"
        @click="handleGoogleLogin"
      >
        <img
          src="resources/images/google-icon.svg"
          alt="Google"
          height="26"
          class="mr-2"
        />
        <div class="font-size-18 justify-content-center">
          Masuk dengan Google
        </div>
        <div ref="googleLoginBtn" hidden />
      </button>

      <div class="d-flex align-items-center justify-content-center my-3">
        <div :class="$style.line"></div>
        <span class="mx-2">atau masuk dengan</span>
        <div :class="$style.line"></div>
      </div>
      <!-- <div class="mb-4">
        <a-radio-group
          v-model="type"
          buttonStyle="solid"
          class="d-flex flex-wrap justify-content-start"
        >
          <a-radio-button class="my-1" value="admin">Admin</a-radio-button>
          <a-radio-button class="my-1" value="verificator"
            >Verificator</a-radio-button
          >
          <a-radio-button class="my-1" value="tagrisso"
            >Tagrisso</a-radio-button
          >
          <a-radio-button class="my-1" value="faslodex"
            >Faslodex</a-radio-button
          >
          <a-radio-button class="my-1" value="lynparza"
            >Lynparza</a-radio-button
          >
          <a-radio-button class="my-1" value="imfinzi">Imfinzi</a-radio-button>
          <a-radio-button class="my-1" value="calquence"
            >Calquence</a-radio-button
          >
        </a-radio-group>
      </div> -->
      <a-form class="mb-4" :form="form" @submit="handleSubmit">
        <a-form-item>
          <div class="h6 font-weight-bold">
            Email<span class="text-danger">*</span>
          </div>
          <a-input
            size="large"
            placeholder="Masukkan email"
            id="email"
            autocomplete="email"
            v-decorator="[
              'email',
              {
                initialValue: '',
                rules: [
                  { required: true, message: 'Please input your Email!' },
                  { type: 'email', message: 'The input is not valid E-mail!' },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item>
          <div class="h6 font-weight-bold">
            Kata Sandi<span class="text-danger">*</span>
          </div>
          <a-input-password
            size="large"
            placeholder="Masukkan kata sandi"
            id="password"
            autocomplete="current-password"
            v-decorator="[
              'password',
              {
                initialValue: '',
                rules: [
                  { required: true, message: 'Please input your Password!' },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-button
          htmlType="submit"
          size="large"
          class="text-center w-100"
          :class="$style.submitButton"
          type="primary"
          :loading="this.isLoading"
          shape="round"
        >
          <strong class="h5">Masuk</strong>
        </a-button>
      </a-form>
      <!-- <router-link
        to="/auth/forgot-password"
        class="font-size-16 float-right text-right"
        >Forgot password?</router-link
      > -->
      <div class="text-left pt-2 mb-auto">
        <span class="mr-2">Belum punya akun?</span>
        <router-link to="/auth/register" class="kit__utils__link font-size-16"
          >Daftar sekarang</router-link
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'CuiLogin',
  computed: {
    ...mapState(['settings']),
    loading() {
      return this.$store.state.user.loading
    },
  },
  data: function () {
    return {
      form: this.$form.createForm(this),
      type: 'admin',
      isLoading: false,
    }
  },
  mounted() {
    const gClientId =
      '737348619545-tlrb4m5pbu3ateh4vjai7p7v9c3mq409.apps.googleusercontent.com'
    window.google.accounts.id.initialize({
      client_id: gClientId,
      callback: this.handleCredentialResponse,
    })
    // Render tombol Google secara eksplisit
    window.google.accounts.id.renderButton(this.$refs.googleLoginBtn, {
      theme: 'outline', // atau 'filled_blue'
      size: 'large',
      text: 'signin_with', // bisa diubah sesuai preferensi
      shape: 'pill', // bisa diubah sesuai preferensi
    })
  },
  methods: {
    changeAuthProvider(value) {
      this.$store.commit('CHANGE_SETTING', { setting: 'authProvider', value })
    },
    handleSubmit(e) {
      this.isLoading = true
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        console.log(values, this.type)
        if (!err) {
          // if (this.type === 'verificator') {
          //   this.$store.dispatch('user/LOGINVERIFICATOR', {
          //     ...values,
          //     role: 'verificator',
          //   })
          // } else {
          const res = await this.$store.dispatch('user/LOGIN', {
            payload: { ...values },
          })
          if (res === false) {
            this.isLoading = false
          }
          // }
        } else {
          console.log(err)
          this.isLoading = false
        }
      })
    },

    handleGoogleLogin() {
      this.$refs.googleLoginBtn.querySelector('div[role=button]').click()
    },

    handleCredentialResponse(response) {
      // console.log(response.credential)
      // console.log('response', response)
      this.$store.dispatch('user/LOGIN_GOOGLE', {
        payload: { ...response },
      })
    },
  },
}
</script>
<style lang="scss" module>
@import '@/components/cleanui/system/Auth/style.module.scss';
</style>
